export enum ClientChatEvents {
  MESSAGE = 'message',
  MESSAGES_WAS_READ = 'messages-was-read',
  INITIAL_MESSAGES = 'initial-messages',
}
export enum ClientVideoEvents {
  CALL_ACCEPTED = 'call-accepted',
  VIDEO_CONTROLS_CHANGED = 'video-controls-changed',
  VIDEO_ROOM_USERS = 'video-room-users',
  RECEIVING_CALL = 'receiving-call',
}

export enum ClientAttendanceEvents {
  ON_STATUS_CHANGE = 'on-attendance-status-change',
}

export const CLIENT_EVENTS = {
  CHAT: ClientChatEvents,
  VIDEO: ClientVideoEvents,
  ATTENDANCE: ClientAttendanceEvents,
};

export enum ServerChatEvents {
  CHAT_MESSAGE = 'chat-message',
  MARK_CHAT_AS_READ = 'mark-chat-as-read',

  JOIN_TO_CHAT_ROOM = 'join-to-chat-room',
  LEAVE_CHAT_ROOM = 'leave-chat-room',
  JOIN_TO_DIRECT_MESSAGES = 'join-to-direct-messages',
  JOIN_TO_ADMIN_MESSAGES = 'join-to-admin-messages',
}
export enum ServerVideoEvents {
  JOIN_TO_VIDEO_ROOM = 'join-to-video-room',
  CALL_TO_USER = 'call-to-user',
  CHANGE_VIDEO_CONTROLS = 'change-video-controls',
  LEAVE_VIDEO_ROOM = 'leave-video-room',
  ACCEPT_VIDEO_CALL = 'accept-video-call',
  GET_VIDEO_ROOM_USERS = 'get-video-room-users',
}

export enum ServerAttendanceEvents {
  JOIN_TO_ATTENDANCE_ROOM = 'join-to-attendance-room',
  LEAVE_ATTENDANCE_ROOM = 'leave-attendance-room',
}

export const SERVER_EVENTS = {
  CHAT: ServerChatEvents,
  VIDEO: ServerVideoEvents,
  ATTENDANCE: ServerAttendanceEvents,
};
