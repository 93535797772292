import { useAuth } from 'contexts/Authentication';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Logger } from 'services/logger';
import { SocketService } from 'services/socketServices/socket.service';

interface SocketState {
  isConnected: boolean;
}
interface SocketContextData {
  socketState: SocketState;
  isSocketConnected?: boolean;
  socketService?: SocketService;
}

const SocketContext = createContext<SocketContextData>({} as SocketContextData);
const logger = new Logger('SocketProvider');
const SocketProvider: React.FC = ({ children }) => {
  const { state } = useAuth();
  const [socketState, setSocketState] = useState<SocketState>({
    isConnected: false,
  } as SocketState);

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [socketService, setSocketService] = useState<SocketService | undefined>(
    undefined,
  );

  useEffect(() => {
    logger.debug(`Mudando isConnected para ${!!socketService}`);
    setSocketState(oldState => ({
      ...oldState,
      isConnected: !!socketService,
    }));
  }, [socketService]);

  useEffect(() => {
    if (state.user && !socketService) {
      logger.debug(`Conectando socket...`);
      const socketService = new SocketService({
        userId: state.user._id as string,
        onConnected: () => {
          setSocketState(oldState => ({
            ...oldState,
            isConnected: true,
          }));

          setIsSocketConnected(true);
        },
        onDisconnected: () => {
          setSocketState(oldState => ({
            ...oldState,
            isConnected: false,
          }));

          setIsSocketConnected(false);
        },

        onReconnected: () => {
          setSocketState(oldState => ({
            ...oldState,
            isConnected: true,
          }));
          setIsSocketConnected(true);
        },
      });

      setSocketService(socketService);
    }

    if (!state.user && socketService) {
      logger.debug(`Disconectando socket...`);
      socketService.disconnect();
      setSocketService(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  return (
    <SocketContext.Provider
      value={
        {
          socketState,
          isSocketConnected,
          socketService,
        } as SocketContextData
      }
    >
      <>{children}</>
    </SocketContext.Provider>
  );
};

function useSocket(): SocketContextData {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context;
}

export { SocketProvider, useSocket };
