import React, { createContext, useState, useContext } from 'react';

interface FloatingChatContextData {
  isOpen: boolean;
  openChat: () => void;
  closeChat: () => void;
}

const FloatingChatContext = createContext<FloatingChatContextData>(
  {} as FloatingChatContextData,
);

const FloatingChatProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const openChat = () => {
    setIsOpen(true);
  };
  const closeChat = () => {
    setIsOpen(false);
  };

  return (
    <FloatingChatContext.Provider
      value={
        {
          openChat,
          closeChat,
          isOpen,
        } as FloatingChatContextData
      }
    >
      {children}
    </FloatingChatContext.Provider>
  );
};

function useFloatingChat(): FloatingChatContextData {
  const context = useContext(FloatingChatContext);

  if (!context) {
    throw new Error(
      'useFloatingChat must be used within a FloatingChatProvider',
    );
  }

  return context;
}

export { FloatingChatProvider, useFloatingChat };
