import { SOCKET_API_URL } from 'config/constants';
import io from 'socket.io-client';
import { OnFunction } from './types';
import { CLIENT_EVENTS, SERVER_EVENTS } from './interfaces/events';
import {
  IJoinChat,
  ILeaveChatDTO,
  IMarkChatAsReadDTO,
  IOnMessagesWasReadDTO,
  IOnReceiveMessageDTO,
  IOnRoomJoinInitalMessagesDTO,
  ISendMessageDTO,
} from './interfaces/chat';
import {
  IAcceptCallDTO,
  ICallToUserDTO,
  IJoinToVideoRoomDTO,
  ILeaveVideoRoomDTO,
  IOnCallAcceptedDTO,
  IOnReceivingCallDTO,
  IOnRoomUsersDTO,
  IOnChangeVideoControls,
  IGetVideoRoomUsersDTO,
} from './interfaces/video';
import { getDirectMessagesRoomId } from './utils';
import { SocketIdentifier } from './interfaces/shared';
import { Logger } from 'services/logger';
import {
  IJoinToAttendanceRoomDTO,
  ILeaveAttendanceRoomDTO,
  IOnAttendanceStatusChangeDTO,
} from './interfaces/attendance';

const logger = new Logger('SocketService');
export interface ISocketServiceConstructor {
  userId: string;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onReconnected?: () => void;
}
export class SocketService {
  public socket: SocketIOClient.Socket;
  private socketConnectionId: string;
  userId: string;

  public isConnected: boolean;
  constructor({
    userId,
    onConnected,
    onDisconnected,
    onReconnected,
  }: ISocketServiceConstructor) {
    logger.debug(`Conectando ao socket ${SOCKET_API_URL}`);
    this.userId = userId;
    this.socket = io(SOCKET_API_URL, {
      query: {
        userId,
      },
      path: '/socket',
      reconnection: true,
    });
    this.socket.connect();

    this.socket.on('disconnect', () => {
      this.onDisconnect();
      if (onDisconnected) onDisconnected();
    });
    this.socket.on('connect', () => {
      this.onConnect();
      if (onConnected) onConnected();
    });
    this.socket.on('reconnect', () => {
      this.onReconnect();
      if (onReconnected) onReconnected;
    });
  }

  onDisconnect = (): void => {
    logger.debug('SocketService was disconnected');
    this.isConnected = false;
  };

  onConnect = (): void => {
    logger.debug('SocketService was connected');
    this.isConnected = true;
  };
  onReconnect = (): void => {
    logger.debug('SocketService was reconnected');
    this.isConnected = true;
  };

  listen = (event: string, func: any): void => {
    if (this.socket && !this.socket.hasListeners(event)) {
      logger.debug(`listening event ${event}`);
      this.socket.on(event, func);
    }
  };

  clear = (event: string) => {
    if (this.socket) {
      this.socket.off(event);
    }
  };

  disconnect(): void {
    if (this.socket) {
      logger.debug(
        `Disconnecting WhatsappSocketService for id ${this.socketConnectionId}`,
      );
      this.socket.disconnect();
    }
  }

  joinToVideoRoom = (data: IJoinToVideoRoomDTO) => {
    if (this.socket) {
      logger.debug(`Joining to video room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.VIDEO.JOIN_TO_VIDEO_ROOM, data);
    }
  };

  getVideoRoomUsers = (data: IGetVideoRoomUsersDTO) => {
    if (this.socket) {
      logger.debug(`Getting connected users from video room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.VIDEO.GET_VIDEO_ROOM_USERS, data);
    }
  };

  leaveVideoRoom = (data: ILeaveVideoRoomDTO) => {
    if (this.socket) {
      logger.debug(`Leaving video room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.VIDEO.LEAVE_VIDEO_ROOM, data);
    }
  };

  joinToAttendanceRoom = (data: IJoinToAttendanceRoomDTO) => {
    if (this.socket) {
      logger.debug(`Joining to attendance room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.ATTENDANCE.JOIN_TO_ATTENDANCE_ROOM, data);
    }
  };

  leaveAttendanceRoom = (data: ILeaveAttendanceRoomDTO) => {
    if (this.socket) {
      logger.debug(`Leaving attendance room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.ATTENDANCE.LEAVE_ATTENDANCE_ROOM, data);
      this.socket.off(CLIENT_EVENTS.ATTENDANCE.ON_STATUS_CHANGE);
    }
  };

  closeVideoCall = () => {
    if (this.socket) {
      logger.debug(`Closing video call listeners`);
      this.socket.off(CLIENT_EVENTS.VIDEO.RECEIVING_CALL);
    }
  };

  callToUser = (data: ICallToUserDTO) => {
    if (this.socket) {
      logger.debug(`Calling to room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.VIDEO.CALL_TO_USER, data);
    }
  };

  acceptVideoCall = (data: IAcceptCallDTO) => {
    if (this.socket) {
      logger.debug(`Accepting call from room ${data.roomId}`);
      this.socket.emit(SERVER_EVENTS.VIDEO.ACCEPT_VIDEO_CALL, data);
    }
  };

  changeVideoControls = (data: IOnChangeVideoControls) => {
    if (this.socket) {
      this.socket.emit(SERVER_EVENTS.VIDEO.CHANGE_VIDEO_CONTROLS, data);
    }
  };

  onChangeVideoControls = (func: OnFunction<IOnChangeVideoControls>) => {
    if (this.socket) {
      this.listen(CLIENT_EVENTS.VIDEO.VIDEO_CONTROLS_CHANGED, func);
    }
  };

  clearOnChangeVideoControls = () => {
    this.clear(CLIENT_EVENTS.VIDEO.VIDEO_CONTROLS_CHANGED);
  };

  onRecevingCall = ({ func }: { func: OnFunction<IOnReceivingCallDTO> }) => {
    if (this.socket) {
      this.listen(CLIENT_EVENTS.VIDEO.RECEIVING_CALL, func);
    }
  };
  onVideoRoomUsers = ({ func }: { func: OnFunction<IOnRoomUsersDTO> }) => {
    if (this.socket) {
      this.listen(CLIENT_EVENTS.VIDEO.VIDEO_ROOM_USERS, func);
    }
  };

  onCallAccepted = ({ func }: { func: OnFunction<IOnCallAcceptedDTO> }) => {
    if (this.socket) {
      this.listen(CLIENT_EVENTS.VIDEO.CALL_ACCEPTED, func);
    }
  };

  joinToChatRoom = (data: IJoinChat) => {
    if (this.socket) {
      logger.debug(`Joining to chat room ${data.chatId}`);
      this.socket.emit(SERVER_EVENTS.CHAT.JOIN_TO_CHAT_ROOM, data);
    }
  };

  leaveChatRoom = (data: ILeaveChatDTO) => {
    if (this.socket) {
      logger.debug(`Leaving from room ${data.chatId}`);
      this.socket.emit(SERVER_EVENTS.CHAT.LEAVE_CHAT_ROOM, data);
    }
  };

  closeChat = () => {
    if (this.socket) {
      this.socket.off(CLIENT_EVENTS.CHAT.MESSAGE);
      this.socket.off(CLIENT_EVENTS.CHAT.INITIAL_MESSAGES);
    }
  };

  closeOnVideoRoomUsers = () => {
    if (this.socket) {
      this.socket.off(CLIENT_EVENTS.VIDEO.VIDEO_ROOM_USERS);
    }
  };

  sendMessage = (data: ISendMessageDTO) => {
    if (this.socket) {
      logger.debug(`Sending message to ${data.chatId}`);
      this.socket.emit(SERVER_EVENTS.CHAT.CHAT_MESSAGE, data);
    }
  };

  markChatAsRead = (data: IMarkChatAsReadDTO) => {
    if (this.socket) {
      logger.debug(`Marking chat ${data.chatId} as read`);
      this.socket.emit(SERVER_EVENTS.CHAT.MARK_CHAT_AS_READ, data);
    }
  };

  onMessage = ({
    func,
    joinToDirectMessages = false,
    joinToAdminMessages = false,
  }: {
    func: OnFunction<IOnReceiveMessageDTO>;
    joinToDirectMessages?: boolean;
    joinToAdminMessages?: boolean;
  }) => {
    if (this.socket) {
      this.listen(CLIENT_EVENTS.CHAT.MESSAGE, func);
      if (joinToDirectMessages) {
        this.socket.emit(SERVER_EVENTS.CHAT.JOIN_TO_DIRECT_MESSAGES, {
          userId: this.userId,
        } as SocketIdentifier);
      }
      if (joinToAdminMessages) {
        this.socket.emit(SERVER_EVENTS.CHAT.JOIN_TO_ADMIN_MESSAGES, {
          userId: this.userId,
        } as SocketIdentifier);
      }
    }
  };

  clearOnMessage = () => {
    this.clear(CLIENT_EVENTS.CHAT.MESSAGE);
  };

  subscribeToDirectMessages = ({
    func,
  }: {
    func: OnFunction<IOnReceiveMessageDTO>;
  }) => {
    this.listen(CLIENT_EVENTS.CHAT.MESSAGE, func);
  };

  onInitialMessages = ({
    func,
  }: {
    func: OnFunction<IOnRoomJoinInitalMessagesDTO>;
  }) => {
    this.listen(CLIENT_EVENTS.CHAT.INITIAL_MESSAGES, func);
  };

  clearOnInitialMessages = () => {
    this.clear(CLIENT_EVENTS.CHAT.INITIAL_MESSAGES);
  };

  onAttendanceStatusChange = ({
    func,
  }: {
    func: OnFunction<IOnAttendanceStatusChangeDTO>;
  }) => {
    this.listen(CLIENT_EVENTS.ATTENDANCE.ON_STATUS_CHANGE, func);
  };

  onChatRead = (func: OnFunction<IOnMessagesWasReadDTO>) => {
    this.listen(CLIENT_EVENTS.CHAT.MESSAGES_WAS_READ, func);
  };

  clearOnChatRead = () => {
    this.clear(CLIENT_EVENTS.CHAT.MESSAGES_WAS_READ);
  };
}
